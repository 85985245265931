import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import { HomeArabic } from "./screens/HomeArabic";
import {Home} from "./screens/Home";

function App() {
  return (
    <div>
       <Routes>
        <Route path="/" element={<HomeArabic />} />
        <Route path="Home" element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
