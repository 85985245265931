import React, { useState, useEffect } from "react";
//import axios from "axios";
import { setKey, setDefaults, geocode, RequestType } from "react-geocode";
import "./Home.css";
import logo from "../assets/logo1.jpg";
import verysatisfied from "../assets/verysatisfied.png";
import generallysatisfied from "../assets/happy.png";
import notsatisfied from "../assets/sad.png";
import arabic from "../assets/arabic.png";
import english from "../assets/english.png";
import { Link } from "react-router-dom";

export const Home = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [currentlat, setCurrentlat] = useState("");
  const [currentlang, setCurrentlang] = useState("");
  const [con, setCon] = useState("");
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [answer5, setAnswer5] = useState("");
  const [feedback, setFeedback] = useState("");

  setDefaults({
    key: "AIzaSyDZPEYiTt0nI7XZ_ue_MX0wuO-kVc4m31o", // Your API key here.
    language: "en", // Default language for responses.
    //  region: "es", // Default region for responses.
  });

  setKey("AIzaSyDZPEYiTt0nI7XZ_ue_MX0wuO-kVc4m31o"); // Your API key here.

  useEffect(() => {
    setTimeout(() => {
      getPosition();
      getConcate();
      getLocation();
      //  getLocation();
    }, 1000);
  });
  const getLocation = () => {
    // Get address from latitude & longitude.
    //    geocode(RequestType.LATLNG, "48.8583701,2.2922926")
    geocode(RequestType.LATLNG, con)
      .then(({ results }) => {
        const address = results[0].formatted_address;
        console.log(address);
        setLocation(address);
      })
      .catch(console.error);
  };

  const getPosition = () => {
    // console.log("I am here");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        //  console.log("Position is", position);
        //  console.log("Latitude is", position.coords.latitude);
        //  console.log("Longitude is", position.coords.longitude);
        setCurrentlat(position.coords.latitude);
        console.log("Latitude is", currentlat);
        setCurrentlang(position.coords.longitude);
        console.log("Longitude is", currentlang);
      });
    }
  };

  const getConcate = () => {
    setCon(currentlat + "," + currentlang);
    console.log(con);
  };

  const handleSubmit = () => {
    if (name.length === 0 || mobile.length === 0) {
      alert("Required Fields are Missing");
    } else {
      console.log(
        "GEO LOCATION API",
        "https://geolocation.appzee.in/backend/api/" +
          "newgeolocation?name=" +
          name +
          "&mobile=" +
          mobile +
          "&email=" +
          email +
          "&location=" +
          location +
          "&question1=" +
          answer1 +
          "&question2=" +
          answer2 +
          "&question3=" +
          answer3 +
          "&question4=" +
          answer4 +
          "&question5=" +
          answer5 +
          "&feedback=" +
          feedback
      );
      fetch(
        "https://geolocation.appzee.in/backend/api/" +
          "newgeolocation?name=" +
          name +
          "&mobile=" +
          mobile +
          "&email=" +
          email +
          "&location=" +
          location +
          "&question1=" +
          answer1 +
          "&question2=" +
          answer2 +
          "&question3=" +
          answer3 +
          "&question4=" +
          answer4 +
          "&question5=" +
          answer5 +
          "&feedback=" +
          feedback,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        // .then((response) => alert(response.data))
        .catch(function (error) {});
      setTimeout(() => {
        document.location.reload();
        alert("Form Submitted Successfully");
      }, 3000);
    }
  };

  return (
    <div>
      <div className="body">
        <img className="logo" src={logo} alt="Logo" />
      </div>

      <div className="body">
        <h1 className="subheading">Select Language</h1>
        <div className="langrow">
          <div className="langview">
            <Link to="/">
              <img className="emojisize" src={arabic} alt="arabic" />
            </Link>
            <p>عربي</p>
          </div>
        </div>
      </div>

      <div className="body">
        <h1 className="headingfont">Complaints & Feedback - English</h1>
      </div>

      <div className="bodyform">
        <form>
          <div className="form-group">
            <label>Name *</label>
            <input
              type="text"
              className="form-control"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Please enter your name"
            />
          </div>

          <div className="form-group">
            <label>Mobile Number *</label>
            <input
              type="text"
              className="form-control"
              name="mobile"
              id="mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Please enter your mobile number"
            />
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Please enter your email address"
            />
          </div>

          <div className="form-group">
            <label>Location</label>
            <input
              type="text"
              className="form-control"
              name="location"
              id="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder={location}
              disabled
            />
          </div>
          <br></br>

          <div className="form-group">
            <label>1. Presense and accessbility of staff?</label>
            <br></br>
            <div className="rowrowview">
              <div className="rowview">
                <img
                  className="emojisize"
                  src={verysatisfied}
                  alt="verysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest1"
                  value="VerySatisfied"
                  onChange={(e) => setAnswer1(e.target.value)}
                />{" "}
                Very Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={generallysatisfied}
                  alt="generallysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest1"
                  value="GenerallySatisfied"
                  onChange={(e) => setAnswer1(e.target.value)}
                />{" "}
                Generally Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={notsatisfied}
                  alt="notsatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest1"
                  value="NotSatisfied"
                  onChange={(e) => setAnswer1(e.target.value)}
                />{" "}
                Not Satisfied
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>2. Cleanliness of the place and facilities?</label>
            <br></br>
            <div className="rowrowview">
              <div className="rowview">
                <img
                  className="emojisize"
                  src={verysatisfied}
                  alt="verysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest2"
                  value="Very Satisfied"
                  onChange={(e) => setAnswer2(e.target.value)}
                />{" "}
                Very Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={generallysatisfied}
                  alt="generallysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest2"
                  value="GenerallySatisfied"
                  onChange={(e) => setAnswer2(e.target.value)}
                />{" "}
                Generally Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={notsatisfied}
                  alt="notsatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest2"
                  value="NotSatisfied"
                  onChange={(e) => setAnswer2(e.target.value)}
                />{" "}
                Not Satisfied
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>
              3. Efficiency, accuracy and quality of service delivery as
              required?
            </label>
            <br></br>
            <div className="rowrowview">
              <div className="rowview">
                <img
                  className="emojisize"
                  src={verysatisfied}
                  alt="verysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest3"
                  value="VerySatisfied"
                  onChange={(e) => setAnswer3(e.target.value)}
                />{" "}
                Very Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={generallysatisfied}
                  alt="generallysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest3"
                  value="GenerallySatisfied"
                  onChange={(e) => setAnswer3(e.target.value)}
                />{" "}
                Generally Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={notsatisfied}
                  alt="notsatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest3"
                  value="NotSatisfied"
                  onChange={(e) => setAnswer3(e.target.value)}
                />{" "}
                Not Satisfied
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>4. Speed of completion of the required service?</label>
            <br></br>
            <div className="rowrowview">
              <div className="rowview">
                <img
                  className="emojisize"
                  src={verysatisfied}
                  alt="verysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest4"
                  value="VerySatisfied"
                  onChange={(e) => setAnswer4(e.target.value)}
                />{" "}
                Very Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={generallysatisfied}
                  alt="generallysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest4"
                  value="GenerallySatisfied"
                  onChange={(e) => setAnswer4(e.target.value)}
                />{" "}
                Generally Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={notsatisfied}
                  alt="notsatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest4"
                  value="NotSatisfied"
                  onChange={(e) => setAnswer4(e.target.value)}
                />{" "}
                Not Satisfied
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>
              5. How satisfied are you with the handling of the service
              provider?
            </label>
            <br></br>
            <div className="rowrowview">
              <div className="rowview">
                <img
                  className="emojisize"
                  src={verysatisfied}
                  alt="verysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest5"
                  value="VerySatisfied"
                  onChange={(e) => setAnswer5(e.target.value)}
                />{" "}
                Very Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={generallysatisfied}
                  alt="generallysatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest5"
                  value="GenerallySatisfied"
                  onChange={(e) => setAnswer5(e.target.value)}
                />{" "}
                Generally Satisfied
              </div>

              <div className="rowview">
                <img
                  className="emojisize"
                  src={notsatisfied}
                  alt="notsatisfied"
                />
                <br></br>
                <input
                  type="radio"
                  name="quest5"
                  value="NotSatisfied"
                  onChange={(e) => setAnswer5(e.target.value)}
                />{" "}
                Not Satisfied
              </div>
            </div>
          </div>

          <br></br>

          <div>
            <div className="form-group">
              <label>Feedback *</label>
              <textarea
                type="text"
                rows={4}
                cols={150}
                minLength={150}
                maxLength={200}
                className="form-control"
                name="feedback"
                id="feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Please enter your feedback"
              />
            </div>
          </div>

          <div className="form-group">
            <button
              type="button"
              className="btn btn-success"
              name="submit"
              id="submit"
              value="Register"
              onClick={handleSubmit}
            >
              submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
